import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Jump Squats (Back Squat) 4×4\\@60% 1RM`}</p>
    <p>{`Glute Ham Raise 4×4 (weighted)`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 1 Lap Run`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`10-Back Squats (185/125)(RX+ 225/155)`}</p>
    <p>{`20-Wall Balls (20/14)(RX+ 30/20)`}</p>
    <p>{`20-GHD Situps`}</p>
    <p>{`Cash Out: 1 Lap Run`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      